import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Box from '@material-ui/core/Box';

import { EMAIL, INSTAGRAM, PHONE } from 'assets/data/actpsicologos-app';
import AppLogo from './AppLogo';
import useStyles from './AppHeaderXs.styles';

const AppHeaderXs = ({ onClickMenu }) => {
  const classes = useStyles();

  return (
    <AppBar className={classes.root} position="fixed">
      <Toolbar>
        <AppLogo onClickMenu={onClickMenu} />
        <Box className={classes.icons}>
          <a
            className={classes.iconsItem}
            href={`tel:+34${PHONE}`}
            rel="noreferrer"
            target="_blank"
          >
            <StaticImage
              alt="phone"
              className={classes.topHeaderItemImg}
              height={24}
              src="../../assets/img/phone-24x24.png"
              width={24}
            />
          </a>
          <Link
            className={classes.iconsItem}
            target="_blank"
            to={`https://instagram.com/${INSTAGRAM}`}
          >
            <StaticImage
              alt="instagram"
              className={classes.topHeaderItemImg}
              height={24}
              src="../../assets/img/instagram-24x24.png"
              width={24}
            />
          </Link>
          <a
            className={classes.iconsItem}
            href={`mailto:${EMAIL}`}
            rel="noreferrer"
            target="_blank"
          >
            <StaticImage
              alt="email"
              className={classes.topHeaderItemImg}
              height={24}
              src="../../assets/img/email-24x24.png"
              width={24}
            />
          </a>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

AppHeaderXs.defaultProps = {
  onClickMenu: null,
};

AppHeaderXs.propTypes = {
  onClickMenu: PropTypes.func,
};

export default AppHeaderXs;
