import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import { isMobile } from 'react-device-detect';
import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import { useMediaQueryTheme } from 'hooks';
import { EMAIL, INSTAGRAM, PHONE } from 'assets/data/actpsicologos-app';
import AppLogo from './AppLogo';
import useStyles from './AppHeaderMd.styles';

const AppHeaderMd = ({ heights, sections, transparent }) => {
  const classes = useStyles({ heights, transparent });
  const lgMatches = useMediaQueryTheme('lg');

  const renderSection = ({ icon, name, to }) => {
    const Icon = icon;
    return (
      <Button
        key={name}
        className={classes.section}
        color="primary"
        size="medium"
        startIcon={lgMatches ? <Icon /> : null}
        variant="outlined"
      >
        <Link to={to}>
          {name}
        </Link>
      </Button>
    );
  };

  return (
    <AppBar className={classes.root} position="absolute">
      <Box className={classes.topHeader}>
        <Link
          className={classes.topHeaderItem}
          target="_blank"
          to={`https://${isMobile ? 'api' : 'web'}.whatsapp.com/send?phone=+34${PHONE}`}
        >
          <StaticImage
            alt="whatsapp"
            className={classes.topHeaderItemImg}
            height={16}
            src="../../assets/img/whatsapp-16x16.png"
            width={16}
          />
          <Typography component="span" variant="body2">
            {`+34 ${PHONE}`}
          </Typography>
        </Link>
        <a
          className={classes.topHeaderItem}
          href={`tel:+34${PHONE}`}
          rel="noreferrer"
          target="_blank"
        >
          <StaticImage
            alt="phone"
            className={classes.topHeaderItemImg}
            height={16}
            src="../../assets/img/phone-16x16.png"
            width={16}
          />
          <Typography component="span" variant="body2">
            {`+34 ${PHONE}`}
          </Typography>
        </a>
        <Link
          className={classes.topHeaderItem}
          target="_blank"
          to={`https://instagram.com/${INSTAGRAM}`}
        >
          <StaticImage
            alt="instagram"
            className={classes.topHeaderItemImg}
            height={16}
            src="../../assets/img/instagram-16x16.png"
            width={16}
          />
          <Typography component="span" variant="body2">
            {`@${INSTAGRAM}`}
          </Typography>
        </Link>
        <a
          className={classes.topHeaderItem}
          href={`mailto:${EMAIL}`}
          rel="noreferrer"
          target="_blank"
        >
          <StaticImage
            alt="email"
            className={classes.topHeaderItemImg}
            height={16}
            src="../../assets/img/email-16x16.png"
            width={16}
          />
          <Typography component="span" variant="body2">
            {EMAIL}
          </Typography>
        </a>
      </Box>
      <Toolbar className={classes.toolbar}>
        {sections.slice(0, 3).map(section => renderSection(section))}
        <Box className={classes.logo}>
          <AppLogo />
        </Box>
        {sections.slice(3, 6).map(section => renderSection(section))}
      </Toolbar>
    </AppBar>
  );
};

AppHeaderMd.defaultProps = {
  heights: {
    main: 100,
    top: 32,
  },
  sections: [],
  transparent: false,
};

AppHeaderMd.propTypes = {
  heights: PropTypes.shape({
    main: PropTypes.number,
    top: PropTypes.number,
  }),
  sections: PropTypes.arrayOf(PropTypes.object),
  transparent: PropTypes.bool,
};

export default AppHeaderMd;
