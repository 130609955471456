import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { isMobile } from 'react-device-detect';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import { useMediaQueryTheme } from 'hooks';
import { EMAIL, INSTAGRAM, PHONE } from 'assets/data/actpsicologos-app';
import EmailIcon from 'assets/img/email-32x32.png';
import InstagramIcon from 'assets/img/instagram-32x32.png';
import PhoneIcon from 'assets/img/phone-32x32.png';
import WhatsappIcon from 'assets/img/whatsapp-32x32.png';
import AppLogo from './AppLogo';
import useStyles from './AppFooter.styles';

const AppFooter = ({ sections }) => {
  const classes = useStyles();
  const mdMatches = useMediaQueryTheme('md');

  const renderSection = ({ name, to }) => (
    <Button key={name} className={classes.section} variant="text">
      <Link to={to}>
        {name}
      </Link>
    </Button>
  );

  return (
    <Box className={classes.root}>
      <Box className={classes.logo}>
        <AppLogo color="white" />
      </Box>
      {mdMatches && (
        <Box className={classes.sections}>
          {sections.map(section => renderSection(section))}
        </Box>
      )}
      <Box className={classes.icons}>
        <Link
          className={classes.iconsItem}
          target="_blank"
          to={`https://${isMobile ? 'api' : 'web'}.whatsapp.com/send?phone=+34${PHONE}`}
        >
          <img
            alt="actpsicologos whatsapp"
            className={classes.topHeaderIcon}
            loading="lazy"
            src={WhatsappIcon}
          />
        </Link>
        <a
          className={classes.iconsItem}
          href={`tel:+34${PHONE}`}
          rel="noreferrer"
          target="_blank"
        >
          <img
            alt="actpsicologos telefono"
            className={classes.topHeaderIcon}
            loading="lazy"
            src={PhoneIcon}
          />
        </a>
        <Link
          className={classes.iconsItem}
          target="_blank"
          to={`https://instagram.com/${INSTAGRAM}`}
        >
          <img
            alt="@actpsicologos"
            className={classes.topHeaderIcon}
            loading="lazy"
            src={InstagramIcon}
          />
        </Link>
        <a
          className={classes.iconsItem}
          href={`mailto:${EMAIL}`}
          rel="noreferrer"
          target="_blank"
        >
          <img
            alt="email"
            className={classes.topHeaderIcon}
            loading="lazy"
            src={EmailIcon}
          />
        </a>
      </Box>
      <Box className={classes.copyright}>
        <Typography variant="body2">
          Made by
          {' '}
          <Link
            className={classes.link}
            target="_blank"
            to="https://www.linkedin.com/in/jlramosr"
          >
            jlramosr
          </Link>
        </Typography>
        <Box className={classes.freepik}>
          <Typography variant="caption">
            <Link
              className={classes.link}
              target="_blank"
              to="https://www.freepik.es/fotos/tecnologia"
            >
              Foto de Tecnología creado por drobotdean - www.freepik.es
            </Link>
          </Typography>
        </Box>
        <Typography variant="button">
          &copy;
          {` Copyright ${new Date().getFullYear()} ACT Psicólogos `}
        </Typography>
      </Box>
    </Box>
  );
};

AppFooter.defaultProps = {
  sections: [],
};

AppFooter.propTypes = {
  sections: PropTypes.arrayOf(PropTypes.object),
};

export default AppFooter;
