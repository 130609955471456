import { alpha } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  copyright: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2, 1),
    textAlign: 'center',
  },
  freepik: {},
  freepikDivider: {
    margin: theme.spacing(0, 0.5),
  },
  icons: {
    display: 'flex',
    padding: theme.spacing(2),
  },
  iconsItem: {
    margin: theme.spacing(0, 1),
  },
  link: {
    color: alpha(theme.palette.secondary.light, 0.5),
  },
  logo: {
    padding: theme.spacing(3, 0, 4),
  },
  root: {
    alignItems: 'center',
    background: theme.palette.primary.dark,
    color: '#ddd',
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
  },
  section: {
    alignItems: 'center',
    color: '#ddd',
    display: 'flex',
  },
  sections: {
    display: 'flex',
  },
}));
