/* eslint import/no-extraneous-dependencies:0 */
import { useTheme } from '@material-ui/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery';

export default (breakpoint = 'md', helper = 'up') => {
  const { breakpoints, props: { MuiUseMediaQuery } } = useTheme();
  return useMediaQuery(
    breakpoints[helper](breakpoint),
    MuiUseMediaQuery,
  );
};
