import { useState } from 'react';
import PropTypes from 'prop-types';
import { isMobile } from 'react-device-detect';
import { Link } from 'gatsby';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import Box from '@material-ui/core/Box';

import { PHONE, ROUTES } from 'assets/data/actpsicologos-app';
import { useMediaQueryTheme } from 'hooks';
import { GoCreditCard, GoHome, GoMilestone } from 'react-icons/go';
import { IoIosPeople } from 'react-icons/io';
import { FaHandsHelping } from 'react-icons/fa';
import { TiContacts } from 'react-icons/ti';
import WhatsappIcon from 'assets/img/whatsapp-64x64.png';
import AppDrawer from './AppDrawer';
import AppHeaderMd from './AppHeaderMd';
import AppHeaderXs from './AppHeaderXs';
import AppSubheaderMd from './AppSubheaderMd';
import AppSubheaderXs from './AppSubheaderXs';
import AppFooter from './AppFooter';
import useStyles from './AppLayout.styles';

const mainHeaderHeight = 132;
const topHeaderHeight = 32;

const AppLayout = ({ children, disableMargin, headerTransparent, subheader }) => {
  const { t } = useTranslation();
  const mdMatches = useMediaQueryTheme('md');
  const classes = useStyles({
    disableMargin,
    mainHeaderHeight,
    subheader,
    topHeaderHeight,
  });
  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const sections = [
    {
      icon: GoHome,
      name: t('routes.home.name'),
      to: ROUTES.HOME,
    },
    {
      icon: GoCreditCard,
      name: t('routes.prices.name'),
      to: ROUTES.PRICES,
    },
    {
      icon: IoIosPeople,
      name: t('routes.team.name'),
      to: ROUTES.TEAM,
    },
    {
      icon: FaHandsHelping,
      name: t('routes.therapies.name'),
      to: ROUTES.THERAPIES,
    },
    {
      icon: GoMilestone,
      name: t('routes.procedure.name'),
      to: ROUTES.PROCEDURE,
    },
    {
      icon: TiContacts,
      name: t('routes.contact.name'),
      to: ROUTES.CONTACT,
    },
  ];

  if (mdMatches) {
    return (
      <Box className={classes.root}>
        <AppHeaderMd
          heights={{
            main: mainHeaderHeight,
            top: topHeaderHeight,
          }}
          sections={sections}
          transparent={headerTransparent}
        />
        <main className={clsx(classes.main, classes.mainMd)}>
          <Box className={classes.toolbarMd} />
          <Box className={clsx(classes.content, classes.contentMd)}>
            {subheader && (
              <AppSubheaderMd headerTransparent={headerTransparent} {...subheader} />
            )}
            {children}
          </Box>
        </main>
        <footer>
          <AppFooter sections={sections} />
        </footer>
      </Box>
    );
  }

  return (
    <Box className={classes.root}>
      <AppHeaderXs onClickMenu={toggleDrawer} />
      <AppDrawer onClose={toggleDrawer} open={drawerOpen} sections={sections} />
      <main className={classes.main}>
        <Box className={classes.toolbar} />
        <Box className={classes.content}>
          {subheader && <AppSubheaderXs {...subheader} />}
          {children}
        </Box>
        <Box className={classes.whatsappIcon}>
          <Link
            target="_blank"
            to={`https://${isMobile ? 'api' : 'web'}.whatsapp.com/send?phone=+34${PHONE}`}
          >
            <img alt="actpsicologos whatsapp" loading="lazy" src={WhatsappIcon} />
          </Link>
        </Box>
      </main>
      <footer>
        <AppFooter sections={sections} />
      </footer>
    </Box>
  );
};

AppLayout.defaultProps = {
  children: null,
  disableMargin: false,
  headerTransparent: false,
  subheader: null,
};

AppLayout.propTypes = {
  children: PropTypes.node,
  disableMargin: PropTypes.bool,
  headerTransparent: PropTypes.bool,
  subheader: PropTypes.shape({
    actions: PropTypes.arrayOf(
      PropTypes.shape({
        icon: PropTypes.func,
        onClick: PropTypes.func,
        title: PropTypes.string,
        to: PropTypes.string,
        type: PropTypes.oneOf(['button', 'switch']),
      }),
    ),
    title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  }),
};

export default AppLayout;
