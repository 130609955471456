import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  actions: {
    alignItems: 'center',
    display: 'flex',
  },
  buttonActions: {
  },
  root: {
    alignItems: 'center',
    borderBottom: `1px solid ${theme.palette.primary.dark}`,
    display: 'flex',
    flexDirection: 'column',
    margin: theme.spacing(8, 0, 2),
    padding: theme.spacing(1),
  },
  switchActions: {
    marginRight: theme.spacing(2),
    textAlign: 'center',
  },
  title: {
    '& h5': {
      fontWeight: 600,
    },
    color: theme.palette.primary.dark,
    marginBottom: theme.spacing(0.5),
  },
}));
