import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  divider: {
    background: theme.palette.grey,
  },
  paper: {
    background: theme.palette.common.white,
    width: ({ width }) => width,
  },
  root: {},
  section: {
    color: theme.palette.primary.main,
    padding: theme.spacing(1.5, 5),
  },
  sectionIcon: {
    color: theme.palette.primary.main,
    marginBottom: 2,
    minWidth: 42,
  },
  sectionText: {
    fontSize: '0.875rem',
  },
  toolbar: {
    ...theme.mixins.toolbar,
    alignItems: 'center',
    display: 'flex',
    marginLeft: 32,
  },
}));
