import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import useStyles from './AppSubheaderXs.styles';

const AppSubheaderXs = ({ actions, title }) => {
  const classes = useStyles();

  const buttonActions = actions.filter(({ type }) => type === 'button');
  const switchActions = actions.filter(({ type }) => type === 'switch');

  const renderTitle = () => (
    <Box className={classes.title}>
      <Typography variant="h3">
        {title}
      </Typography>
    </Box>
  );

  const renderSwitchActions = () => (
    Boolean(switchActions.length) && (
      <Box className={classes.switchActions}>
        {switchActions.map(action => (
          <FormControlLabel
            key={action.title}
            control={(
              <Switch
                checked={action.value}
                color="primary"
                onChange={event => action.onClick(event.target.checked)}
              />
            )}
            label={(
              <Typography variant="caption">
                {action.title}
              </Typography>
            )}
            labelPlacement="start"
            size="small"
          />
        ))}
      </Box>
    )
  );

  const renderButtonActions = () => (
    Boolean(buttonActions.length) && (
      <Box className={classes.buttonActions}>
        {buttonActions.map(action => (
          <Button key={action.title}>{action.title}</Button>
        ))}
      </Box>
    )
  );

  return (
    <Box className={classes.root}>
      {renderTitle()}
      <Box className={classes.actions}>
        {renderSwitchActions()}
        {renderButtonActions()}
      </Box>
    </Box>
  );
};

AppSubheaderXs.defaultProps = {
  actions: [],
  title: '',
};

AppSubheaderXs.propTypes = {
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.func,
      onClick: PropTypes.func,
      title: PropTypes.string,
      to: PropTypes.string,
      value: PropTypes.oneOfType[(PropTypes.string, PropTypes.string)],
    }),
  ),
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
};

export default AppSubheaderXs;
