import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  content: {
    [`${theme.breakpoints.down('md')} and (orientation: landscape)`]: {
      marginTop: ({ disableMargin }) => (disableMargin ? 0 : 48),
    },
    flexGrow: 1,
    height: '100%',
    marginTop: ({ disableMargin }) => (disableMargin ? 0 : 56),
  },
  contentMd: {
    marginTop: ({ disableMargin, mainHeaderHeight, topHeaderHeight }) => (
      disableMargin
        ? 0
        : mainHeaderHeight + topHeaderHeight
    ),
  },
  main: {
    [`${theme.breakpoints.down('md')} and (orientation: landscape)`]: {
      marginTop: -48,
    },
    flexGrow: 1,
    marginTop: -56,
  },
  mainMd: {
    marginTop: ({ mainHeaderHeight, topHeaderHeight }) => (
      -(mainHeaderHeight + topHeaderHeight)
    ),
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    minWidth: 320,
  },
  toolbar: theme.mixins.toolbar,
  toolbarMd: {
    minHeight: ({ mainHeaderHeight, topHeaderHeight }) => (
      mainHeaderHeight + topHeaderHeight
    ),
  },
  whatsappIcon: {
    bottom: 12,
    cursor: 'pointer',
    left: 12,
    position: 'fixed',
    zIndex: 100,
  },
}));
