import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import AppLogo from './AppLogo';

import useStyles from './AppDrawer.styles';

const width = 240;

const AppDrawer = ({ onClose, open, sections }) => {
  const classes = useStyles({ width });

  const renderSection = ({ icon, name, state, to }) => {
    const Icon = icon;
    return (
      <Link
        key={name}
        state={state}
        to={to}
      >
        <ListItem key={name} button className={classes.section}>
          <ListItemIcon className={classes.sectionIcon}>
            <Icon size={20} />
          </ListItemIcon>
          <ListItemText classes={{ primary: classes.sectionText }} primary={name} />
        </ListItem>
      </Link>
    );
  };

  return (
    <nav aria-label="drawer" className={classes.root}>
      <Drawer
        anchor="left"
        classes={{
          paper: classes.paper,
        }}
        onClose={onClose}
        open={open}
        variant="temporary"
      >
        <Box className={classes.content}>
          <Box className={classes.toolbar}>
            <AppLogo color="primary" />
          </Box>
          <Divider className={classes.divider} />
          <List>
            {sections.map((section, index) => (
              renderSection(section)
            ))}
          </List>
        </Box>
      </Drawer>
    </nav>
  );
};

AppDrawer.defaultProps = {
  sections: [],
};

AppDrawer.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  sections: PropTypes.arrayOf(PropTypes.object),
};

export default AppDrawer;
