import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  actions: {
    alignItems: 'center',
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end',
  },
  buttonActions: {
    marginLeft: theme.spacing(2),
  },
  root: {
    alignSelf: 'center',
    display: 'flex',
    flexDirection: 'row',
  },
  switchActions: {
    textAlign: 'center',
  },
  title: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: ({ headerTransparent }) => (
      headerTransparent ? 0 : theme.spacing(3)
    ),
    width: '100%',
  },
  titleDivider: {
    background: `linear-gradient(to right, ${theme.palette.primary.dark}, transparent)`,
    height: 1,
  },
  titleText: {
    '& h4': {
      fontWeight: 600,
    },
    color: theme.palette.primary.dark,
    margin: theme.spacing(0, 3),
  },
}));
