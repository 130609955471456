import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  icons: {},
  iconsItem: {
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(2),
    },
    marginLeft: theme.spacing(1),
  },
  root: {
    background: theme.palette.common.white,
    display: 'flex',
    flex: 1,
  },
}));
