import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  icon: {
    height: 32,
    marginRight: theme.spacing(2),
    width: 32,
  },
  logo: {
    height: 42,
    marginBottom: 2,
    [theme.breakpoints.up('md')]: {
      height: 64,
    },
  },
  root: {
    alignItems: 'center',
    display: 'flex',
    flex: 1,
  },
}));
