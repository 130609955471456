import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import { MdMenu } from 'react-icons/md';

import logoPrimary from 'assets/img/logo-primary.png';
import logoWhite from 'assets/img/logo-white.png';
import useStyles from './AppLogo.styles';

const AppLogo = ({ color, onClickMenu }) => {
  const classes = useStyles({ color });
  const src = color === 'white' ? logoWhite : logoPrimary;

  return (
    <Box className={classes.root}>
      {onClickMenu && (
        <IconButton
          aria-label="menu"
          color={color === 'white' ? 'inherit' : 'primary'}
          edge="start"
          onClick={onClickMenu}
        >
          <MdMenu />
        </IconButton>
      )}
      <img
        alt="actpsicologos-logo"
        className={classes.logo}
        src={src}
      />
    </Box>
  );
};

AppLogo.defaultProps = {
  color: 'primary',
  onClickMenu: null,
};

AppLogo.propTypes = {
  color: PropTypes.oneOf(['primary', 'white']),
  onClickMenu: PropTypes.func,
};

export default AppLogo;
