import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import useStyles from './AppSubheaderMd.styles';

const AppSubheaderMd = ({ actions, headerTransparent, title }) => {
  const classes = useStyles({ headerTransparent });

  const buttonActions = actions.filter(({ type }) => type === 'button');
  const switchActions = actions.filter(({ type }) => type === 'switch');

  const renderTitle = () => (
    <Box className={classes.title}>
      <Box className={classes.titleText}>
        <Typography variant="h3">
          {title}
        </Typography>
      </Box>
      <Box className={classes.titleDivider} />
    </Box>
  );

  const renderSwitchActions = () => (
    Boolean(switchActions.length) && (
      <Box className={classes.switchActions}>
        {switchActions.map(action => (
          <FormControlLabel
            key={action.title}
            control={(
              <Switch
                checked={action.value}
                color="primary"
                onChange={event => action.onClick(event.target.checked)}
              />
            )}
            label={(
              <Typography variant="body2">
                {action.title}
              </Typography>
            )}
            labelPlacement="start"
            size="small"
          />
        ))}
      </Box>
    )
  );

  const renderButtonActions = () => (
    Boolean(buttonActions.length) && (
      <Box className={classes.buttonActions}>
        {buttonActions.map(action => (
          <Button key={action.title}>{action.title}</Button>
        ))}
      </Box>
    )
  );

  return (
    <Container className={classes.root} maxWidth="lg">
      {renderTitle()}
      <Box className={classes.actions}>
        {renderSwitchActions()}
        {renderButtonActions()}
      </Box>
    </Container>
  );
};

AppSubheaderMd.defaultProps = {
  actions: [],
  headerTransparent: false,
  title: '',
};

AppSubheaderMd.propTypes = {
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.func,
      onClick: PropTypes.func,
      title: PropTypes.string,
      to: PropTypes.string,
      value: PropTypes.oneOfType[(PropTypes.string, PropTypes.string)],
    }),
  ),
  headerTransparent: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
};

export default AppSubheaderMd;
