module.exports = {
  EMAIL: 'admin@actpsicologos.com',
  INSTAGRAM: 'actpsicologos',
  PHONE: '630319631',
  ROUTES: {
    CONTACT: '/contacta/',
    HOME: '/',
    PRICES: '/tarifas/',
    PRIVACY_POLICY: '/politica-de-privacidad/',
    PROCEDURE: '/procedimiento/',
    TEAM: '/equipo/',
    THERAPIES: '/terapias/',
  },
  URL_PROD: 'https://actpsicologos.com',
};
